<template>
  <v-container>
    <title-header />
    <v-card>
      <v-row class="px-5 py-1 d-flex justify-space-between">
        <v-col class="d-inline-flex align-center" cols="12" md="4" xl="3">
          <span class="mr-2 font-weight-medium">Motorista:</span>
          <v-select
            :items="drivers"
            item-text="fullName"
            item-value="id"
            outlined
            multiple
            chips
            @change="setFilter('drivers', $event)"
            color="secondary-color-1"
            label="Todos os motoristas"
          ></v-select>
        </v-col>
        <v-col
          v-if="userIsAdmin"
          class="d-inline-flex align-center"
          cols="12"
          md="4"
          xl="3"
        >
          <span class="mr-2 font-weight-medium">CRC:</span>
          <v-select
            :items="crcs"
            item-text="description"
            item-value="id"
            outlined
            @change="setFilter('crcId', $event)"
            color="secondary-color-1"
            label="Todos os CRCs"
          ></v-select>
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
    </v-card>
    <v-card class="mt-5">
      <template v-if="collectionSchedule.length">
        <v-list>
          <template v-for="(item, index) in collectionSchedule">
            <v-list-item :key="item.id">
              <v-list-item-content>
                <v-row>
                  <v-col cols="12" sm="12" md="3" xl="4">
                    <div
                      class="d-flex flex-wrap list-content--text black-1--text"
                    >
                      <span class="font-weight-medium mr-3"
                        >Agendamento {{ item.id }}</span
                      >
                      <div>
                        <span class="font-weight-medium">Data:</span>
                        <span class="ml-1">{{ item.createdAt }}</span>
                      </div>
                    </div>
                    <p class="mt-3 list-content--text black-1--text">
                      <span class="font-weight-medium"
                        >Status do agendamento:</span
                      >
                      <span class="ml-1">{{ item.situation }}</span>
                    </p>
                  </v-col>
                  <v-col cols="12" sm="12" md="7" xl="6">
                    <div
                      class="white-3--text d-flex flex-wrap list-content--text"
                    >
                      <div>
                        <span class="font-weight-medium">Turno:</span>
                        <span class="ml-1 mr-3">{{ item.shift }}</span>
                      </div>
                      <div>
                        <span class="font-weight-medium">Motorista:</span>
                        <span class="ml-1 mr-3">{{ item.driver }}</span>
                      </div>
                      <div>
                        <span class="font-weight-medium">Coletas:</span>
                        <span class="ml-1 mr-3">
                          {{ item.amountOfCollections }}
                        </span>
                      </div>
                      <div>
                        <span class="font-weight-medium">CRC:</span>
                        <span class="ml-1 mr-3">{{
                          item.crc.description
                        }}</span>
                      </div>
                    </div>
                    <div class="white-3--text mt-3 list-content--text">
                      <span class="font-weight-medium">Observações:</span>
                      <span class="ml-1">{{ item.observation }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" xl="2">
                    <v-btn
                      @click="goToViewRequest(item.id)"
                      color="secondary-color-1"
                      max-width="100"
                      class="white--text next-step-button"
                      >Ver mais</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index < collectionSchedule.length - 1"
              :key="'divider-' + index"
            ></v-divider>
          </template>
        </v-list>
        <div v-if="collectionSchedule.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </template>

      <template v-if="!collectionSchedule.length">
        <div class="pt-10">
          <data-not-found
            v-if="!filterPristine"
            title="Coleta não encontrada."
            :imgSrc="require('@/assets/truck.svg')"
          />

          <data-not-found
            v-if="filterPristine"
            title="Você ainda não possui nenhuma coleta agendada"
            btnLabel="Cadastrar o primeiro agendamento"
            :btnAction="goToAddCollection"
            :imgSrc="require('@/assets/truck.svg')"
          />
        </div>
      </template>
    </v-card>
  </v-container>
</template>
<script>
  import { parseISO } from "date-fns";
  import { isEmpty } from "lodash";
  import { mapActions, mapGetters } from "vuex";

  import TableHandler from "@/modules/core/mixins/tableHandler";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import { profiles } from "@/modules/core/enums/profiles.enum";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import Pagination from "@/modules/core/views/components/Pagination";

  export default {
    name: "Collections",

    mixins: [MasksMixin, TableHandler],

    components: {
      Pagination,
      TitleHeader,
      DataNotFound
    },

    data: () => ({
      collectionSchedule: [],
      drivers: [],
      crcs: [
        {
          id: null,
          description: null
        }
      ]
    }),

    created() {
      this.initFilter("roles", profiles.DRIVER.id);
      if (this.userIsAdmin) {
        this.initFilter("crcId", null);
      }
      this.initFilter("drivers", null);
    },

    async mounted() {
      this.fetchDrivers();
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
      await this.getColletionData();
    },

    computed: {
      ...mapGetters("user", {
        userIsAdmin: "getUserIsAdmin"
      })
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("user", ["getUsers"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions("agenda", ["getCollectionSchedule"]),

      async getColletionData() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getCollectionSchedule(payload);
          this.collectionSchedule = this.formatCollectionScheduleData(data);

          this.setTableInfo(meta);
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatCollectionScheduleData(collection) {
        return collection.map(data => {
          const phone = !isEmpty(data.driver?.phones[0])
            ? this.parsePhone(data.driver?.phones[0])
            : "não informado";

          const parsedDate = parseISO(data.scheduleDate);

          return {
            ...data,
            id: data.id,
            createdAt: this.parseDate(parsedDate, "dd/MM/yyyy"),
            shift: data.shift,
            driver: `${data.driver?.firstName} ${data.driver?.surname}`,
            driverContact: phone,
            amountOfCollections: data.amountOfCollections,
            phone: phone ? phone : "Não informado",
            situation: data.situation?.description,
            address: data.address
          };
        });
      },

      async fetchDrivers() {
        try {
          const payload = this.getPayloadData();
          const { data } = await this.getUsers(payload);
          this.drivers = this.formatUsersData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            ...data,
            {
              description: "Todos",
              id: ""
            }
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatUsersData(users) {
        return users.map(user => {
          return {
            id: user.id,
            fullName: `${user.firstName} ${user.surname}`
          };
        });
      },

      goToViewRequest(requestId) {
        return this.$router.push({
          name: "collectionDetail",
          params: { id: requestId }
        });
      },

      goToAddCollection() {
        this.$router.push({ name: "newSchedule" });
      },

      async updateData() {
        await this.getColletionData();
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .list-content--text {
    font-size: 14px;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .v-input__slot {
    margin: 0;
  }

  ::v-deep .v-select--chips:not(.v-input--is-dirty) > .v-input__control {
    height: 56px;
  }
</style>
